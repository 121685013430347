import React, { useState, useEffect, useContext } from "react";
import { useLocation, useParams } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { styled } from "@mui/material/styles";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import secureLocalStorage from "react-secure-storage";
import fileContext from "../context/fileContext";
import toast, { Toaster } from "react-hot-toast";
import useAuth from "../stores/authStore";
import { auth, storage } from "../helper/firebaseClient";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { api } from "../utils/axios-instance";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";
import FileView from "./FileView";
import { useDepartment } from "../stores/departmentStore";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "#3B82F6" : "#B3A9EB",
  },
}));

const wordTemplateOptions = [
  { title: "Basic", template: "docx_template.docx" },
  { title: "Report", template: "docx_report_template.docx" },
  { title: "Letter", template: "docx_letter_template.docx" },
  // { title: "Resume", template: "docx_resume_template.docx" },
  { title: "Newsletter", template: "docx_newsletter_template.docx" },
  { title: "Card", template: "docx_card_template.docx" },
  { title: "Certificate", template: "docx_certificate_template.docx" },
  {
    title: "Business Proposal",
    template: "docx_project_propsal_template.docx",
  },
  { title: "NDA Agreement", template: "docx_nda_agreement_template.docx" },
  { title: "Meeting Minutes", template: "docx_minutes.docx" },
];

const excelTemplateOptions = [
  { title: "Basic", template: "xlsx_template.xlsx" },
  { title: "Table And Chart", template: "xlsx_tableandchart_template.xlsx" },
  { title: "Checklist", template: "xlsx_checklist_template.xlsx" },
  { title: "Categories", template: "xlsx_categories_template.xlsx" },
  { title: "Budget", template: "xlsx_budget_template.xlsx" },
  { title: "Team Roaster", template: "xlsx_team_roaster.xlsx" },
  { title: "Project Tracking", template: "xlsx_project_tracking.xlsx" },
  { title: "Expense Report", template: "xlsx_expense_report.xlsx" },
  { title: "Project Timeline", template: "xlsx_project_timeline.xlsx" },
  // { "title": "Customer Relationship Management", "template": "xlsx_customer_relationship_management.xlsx" },
  { title: "Invoice", template: "xlsx_invoice.xlsx" },
  { title: "Purchase Order", template: "xlsx_purchase_order.xlsx" },
  { title: "Employee Shift", template: "xlsx_employee_shift_schedule.xlsx" },
  { title: "Schedule", template: "xlsx_schedule.xlsx" },
];

const fileOptions = [
  {
    title: "Word Document",
    extension: ".docx",
    type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    templateOptions: wordTemplateOptions,
  },
  {
    title: "Excel Sheet",
    extension: ".xlsx",
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    templateOptions: excelTemplateOptions,
  },
];

const CreateFile = ({ value }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [uploadProgress, setUploadProgress] = useState(0);
  const [depts, setdepts] = useState([]);
  const [deptId, setDeptId] = useState("");
  const [selectedDeptIndex, setSelectedDeptIndex] = useState(null);
  const [isFieldsFilled, setIsFieldsFilled] = useState(false);
  const [name, setName] = useState("");
  const [type, setType] = useState("");
  const [fileInfo, setFileInfo] = useState({});
  const [isFileOpen, setIsFileOpen] = useState(false);
  const [isClicked, setIsClicked] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState("");

  const profileData = useAuth((state) => state.profileData);
  const { deptName } = useParams();
  const context = useContext(fileContext);
  const { updateFilesState, updateDepartmentFiles } = context;
  const departments = useDepartment((state) => state.departments);

  // Function to check if both fields are filled
  const checkFields = () => {
    if (deptId !== "" && name.trim() !== "" && type !== "") {
      setIsFieldsFilled(true);
    } else {
      setIsFieldsFilled(false);
    }
  };

  // Add useEffect to check fields whenever deptId or droppedFiles change
  useEffect(() => {
    checkFields();
  }, [deptId, name, type]);

  const handleDepartmentClick = (index, id) => {
    setDeptId(id);
    setSelectedDeptIndex(index);
  };

  const uploadFile = async (file, id) => {
    return new Promise((resolve, reject) => {
      const fileRef = ref(storage, `files/${profileData.org}/${id}`);
      const metadata = {
        customMetadata: {
          department_id: deptId,
          org_id: profileData.org,
        },
      };

      const uploadTask = uploadBytesResumable(fileRef, file, metadata);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setUploadProgress(progress);
        },
        (error) => {
          console.error("File upload error:", error);
          reject(error);
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            resolve(downloadURL);
          });
        }
      );
    });
  };

  const handleFinalUpload = async () => {
    setIsClicked(true);
    const templateFileRef = ref(
      storage,
      `files/templates/${selectedTemplate.template}`
    );
    const downloadURL = await getDownloadURL(templateFileRef);
    const response = await axios.get(downloadURL, {
      responseType: "arraybuffer",
    });

    const blob = new Blob([response.data], {
      type: type.type,
    });

    const file = new File([blob], name + type.extension, { type: type.type });

    try {
      const newFileId = uuidv4();
      const downloadURL = await uploadFile(file, newFileId);
      await handleFileIdRetrieval(file, file.name, downloadURL, newFileId);
      setIsFileOpen(true);
      closeDialog();
    } catch (error) {
      console.error("Error occurred in file upload:", error);
      showSnackbar("Upload failed. Please try again.", "error");
    } finally {
      setUploadProgress(0);
    }
  };

  const handleFileIdRetrieval = async (
    file,
    desiredFileName,
    downloadURL,
    newFileId
  ) => {
    const token = await auth.currentUser.getIdToken();
    try {
      const res = await api.post(`/file/addDepartment/${newFileId}`, {
        department_ids: [deptId],
        new: true,
        name: desiredFileName,
        downloadURL: downloadURL,
        idToken: token,
        metadata: {
          size: file.size,
          mimetype: file.type,
          lastModified: new Date().toLocaleString("en-IN", {
            day: "numeric",
            month: "short",
            year: "numeric",
            hour: "numeric",
            minute: "numeric",
            hour12: true,
          }),
        },
      });

      const filteredDepartment = departments.filter(
        (dept) => dept.id === deptId
      );

      const fetchedFile = res?.data?.file;
      const fileInfo = {
        name: fetchedFile.name,
        size: fetchedFile.metadata.size,
        id: fetchedFile.id,
        owner: fetchedFile.owner_id,
        ownerProfileUrl: fetchedFile.profile_pic,
        lastUpdate: new Date(fetchedFile.metadata.lastModified).toLocaleString(
          "en-IN",
          {
            day: "numeric",
            month: "short",
            year: "numeric",
            hour: "numeric",
            minute: "numeric",
            hour12: true,
          }
        ),
        mimetype: fetchedFile.metadata.mimetype,
        downloadURL: fetchedFile.downloadURL,
        color: filteredDepartment[0]?.metadata.bg,
      };
      setFileInfo(fileInfo);

      toast.success("File created successfully");

      updateFilesState(value);
      if (deptName) {
        updateDepartmentFiles(deptName);
      }
    } catch (error) {
      console.log(error);
      toast.error("Error creating file");
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const showSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  const openDialog = () => {
    setIsOpen(true);
    const departments = JSON.parse(secureLocalStorage.getItem("departments"));

    setdepts(departments);
  };

  const closeDialog = () => {
    setIsOpen(false);
    setUploadProgress(0);
    setSelectedDeptIndex(null);
    setType("");
    setIsClicked(false);
    setName("");
    setDeptId("");
    setSelectedTemplate("");
  };

  return (
    <div className="">
      <Toaster reverseOrder={false} position="bottom-left" />
      <button
        onClick={openDialog}
        title="create folder"
        className="text-2xl rounded-lg px-3 ml-2 mr-4 text-center text-purple-600 border border-purple-600"
      >
        +
      </button>

      <Dialog
        open={isOpen}
        onClose={closeDialog}
        PaperProps={{
          style: {
            borderRadius: "16px",
          },
        }}
      >
        <DialogTitle>
          <span>
            <h4 className="font-semibold text-md">Create a file</h4>
          </span>
        </DialogTitle>
        <DialogContent
          style={{
            backgroundColor: "#F7F8FA",
            borderTop: "1px solid #ddd",
            borderBottom: "1px solid #ddd",
          }}
        >
          <div className="flex my-4 w-full">
            <h1 className="text-nowrap">Name of File: </h1>
            <input
              type="text"
              className="ml-2 rounded-lg flex-1 px-1"
              style={{ border: "0.5px solid black" }}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <div className="my-4">
            <h1>File Type:</h1>
            <div className="grid grid-cols-3 gap-2 my-4">
              {fileOptions.map((option, index) => (
                <div
                  key={index}
                  style={{
                    border:
                      type.title === option.title
                        ? "2px solid black"
                        : "1px solid silver", // Applying border to selected department
                  }}
                  className={`flex justify-center items-center p-2 rounded-lg cursor-pointer`}
                  onClick={() => setType(option)}
                >
                  {option.title}
                </div>
              ))}
            </div>
            {type && (
              <>
                <h1>File Template:</h1>
                <div className="grid grid-cols-3 gap-2 my-4">
                  {type?.templateOptions?.map((option, index) => (
                    <div
                      key={index}
                      style={{
                        border:
                          selectedTemplate.title === option.title
                            ? "2px solid black"
                            : "1px solid silver",
                      }}
                      className={`flex justify-center items-center p-2 rounded-lg cursor-pointer`}
                      onClick={() => setSelectedTemplate(option)}
                    >
                      {option.title}
                    </div>
                  ))}
                </div>
              </>
            )}
            <h1>File Department:</h1>
            <div className="grid grid-cols-3 gap-2 my-4">
              {depts.map((dept, index) => (
                <div
                  key={index}
                  style={{
                    backgroundColor: dept.metadata.bg,
                    border:
                      selectedDeptIndex === index
                        ? "2px solid black"
                        : "1px solid silver", // Applying border to selected department
                  }}
                  className={`flex justify-center items-center p-2 rounded-lg cursor-pointer`}
                  onClick={() => handleDepartmentClick(index, dept.id)}
                >
                  {dept.name.replace("_", " ")}
                </div>
              ))}
            </div>

            {uploadProgress > 0 && (
              <BorderLinearProgress
                variant="determinate"
                value={uploadProgress}
              />
            )}
          </div>
        </DialogContent>
        <DialogActions sx={{ padding: "10px" }}>
          <div className="flex justify-between w-full px-2">
            <button
              className="px-3 py-1.5 rounded-lg shadow-sm border border-gray-300 hover:bg-gray-100 text-sm font-semibold"
              onClick={closeDialog}
              color="primary"
            >
              Cancel
            </button>
            <button
              className={`px-3 py-1.5 rounded-lg shadow-sm border ${
                !isFieldsFilled
                  ? "border-gray-300 text-gray-300 cursor-not-allowed"
                  : uploadProgress > 0
                    ? "border-gray-500 text-gray-500 hover:bg-gray-200 cursor-progress"
                    : "border-[#5E5ADB] text-[#5E5ADB] hover:bg-blue-100"
              } text-sm font-semibold`}
              onClick={handleFinalUpload}
              disabled={!isFieldsFilled || uploadProgress > 0 || isClicked}
            >
              Create
            </button>
          </div>
        </DialogActions>
        {uploadProgress > 90 && (
          <Snackbar
            open={snackbarOpen}
            autoHideDuration={6000}
            onClose={handleSnackbarClose}
          >
            <MuiAlert
              onClose={handleSnackbarClose}
              severity={snackbarSeverity}
              sx={{ width: "100%" }}
            >
              {snackbarMessage}
            </MuiAlert>
          </Snackbar>
        )}
      </Dialog>

      {isFileOpen && (
        <FileView
          fileInfo={fileInfo}
          closeDrawer={() => setIsFileOpen(false)}
        />
      )}
    </div>
  );
};

export default CreateFile;
