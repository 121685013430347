import React, { useEffect } from "react";
import Department from "./pages/Department";
import SignUp from "./pages/SignUp";
import Login from "./pages/Login";
import Dashboard from "./pages/Dashboard";
import SideBar from "./components/SideBar";
import TopBar from "./components/TopBar";
import Onboarding from "./pages/Onboarding";
import Onboard from "./pages/Onboard";
import Profile from "./pages/Profile";
import UserManagement from "./pages/UserManagement";
import Test from "./pages/Test";
import Background from "./components/Background";
import Settings from "./pages/Settings";
import UserProfile from "./pages/UserProfile";
import AI from "./pages/AI";
import Analytics from "./pages/Analytics";
import ContactUs from "./pages/ContactUs";
import ForgotPassword from "./pages/ForgotPassword";
import ChangePassword from "./pages/ChangePassword";
import FilesInsideFolder from "./components/FilesInsideFolder";
// import secureLocalStorage from "react-secure-storage";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ProtectedRoute } from "./components/ProtectedRoute";
import ErrorPage from "./components/ErrorPage";
import TextEditor from "./components/FileEditors/TextEditor";
import CustomEditor from "./components/customEditor/CustomEditorV1";
import WaitingLobby from "./components/WaitingLobby";
import LandingPrev from "./pages/LandingPrev";
import Landing from "./pages/Landing";
import AuthHandler from "./pages/AuthHandler";
import Chat from "./pages/Chat";
import RecycleBin from "./pages/RecycleBin";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import useAuth from "./stores/authStore";
import { auth } from "./helper/firebaseClient";
// import { useDarkMode } from "./context/darkModeContext";

// let hardCodedDepartments = [
//   { name: "Account", metadata: { bg: "#FFF6F6", border: "#FEB7B7" } },
//   { name: "Finance", metadata: { bg: "#FFF6FF", border: "#FFA9FF" } },
//   { name: "Development", metadata: { bg: "#F6FFF6", border: "#B3FFB3" } },
//   { name: "Manufacturing", metadata: { bg: "#F6F7FF", border: "#B6BEFF" } },
//   { name: "Sales", metadata: { bg: "#FFFFF6", border: "#FFFFA1" } },
//   { name: "Human Resources", metadata: { bg: "#F6FFFE", border: "#C0FFF8" } },
// ];

// const REFRESH_INTERVAL = 24 * 60 * 60 * 1000;

// let fetchedDepartments = JSON.parse(secureLocalStorage.getItem("departments"));

const queryClient = new QueryClient();
const App = () => {
  // const { refreshAccessToken } = useAuth();
  // const isDarkMode = useDarkMode();

  // TODO: implement refresh token logic

  // useEffect(() => {
  //   const intervalId = setInterval(() => {
  //     refreshAccessToken();
  //   }, REFRESH_INTERVAL);
  //   return () => clearInterval(intervalId);
  // }, [refreshAccessToken]);

  useEffect(() => {
    // document.addEventListener("keydown", detectPrintScreen);
    document.addEventListener(
      "contextmenu",
      useAuth.getState().handleContextMenu
    );
    return () => {
      // document.removeEventListener("keydown", detectPrintScreen);
      document.removeEventListener(
        "contextmenu",
        useAuth.getState().handleContextMenu
      );
    };
  }, []);

  useEffect(() => {
    // Listen for auth state changes
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        useAuth.setState({ token: user.getIdToken() });
        useAuth.getState().fetchProfileData();
      } else {
        useAuth.setState({ token: null });
      }
    });
    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <Router>
        <div className="md:flex ">
          <SideBar />
          <div className="w-full">
            <TopBar />
            <Background />
            <Routes>
              <Route path="/" element={<LandingPrev />} />
              {/* <Route path="/" element={<Landing />} /> */}
              <Route path="/contact-us" element={<ContactUs />} />
              <Route path="/onboarding" element={<Onboarding />} />
              <Route path="/signup" element={<SignUp />} />
              <Route path="/login" element={<Login />} exact />
              <Route path="/forgot-password" element={<ForgotPassword />} />
              <Route path="/change-password" element={<ChangePassword />} />
              <Route path="/_/auth_handler" element={<AuthHandler />} />

              {/* Protected Routes */}
              <Route element={<ProtectedRoute />}>
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/chat" element={<Chat />} />
                <Route path="/user-management" element={<UserManagement />} />
                <Route path="/department/:deptName" element={<Department />} />
                <Route path="/user-profile/:userId" element={<UserProfile />} />
                <Route
                  path="/filesInsideFolder/:folderName/:folderId"
                  element={<FilesInsideFolder />}
                />
                <Route path="/profile" element={<Profile />} />
                <Route path="/settings" element={<Settings />} />
                <Route path="/onboard" element={<Onboard />} />
                <Route path="/waiting-lobby" element={<WaitingLobby />} />

                <Route path="/analytics" element={<Analytics />} />
                <Route path="/ai/:fileId" element={<AI />} />
                <Route path="/test" element={<Test />} />

                {/* for editor testing */}
                <Route path="/edit" element={<TextEditor />} />
                <Route path="/editcustom" element={<CustomEditor />} />

                {/* for Recycle Bin */}
                <Route path="/recyclebin" element={<RecycleBin />} />
              </Route>
              <Route
                path="*"
                element={
                  <ErrorPage error={"We could not find the requested page."} />
                }
              />
            </Routes>
          </div>
        </div>
      </Router>
    </QueryClientProvider>
  );
};

export default App;
