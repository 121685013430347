import React, { useContext, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { supabase } from "../helper/supabaseClient";
import secureLocalStorage from "react-secure-storage";
import fileContext from "../context/fileContext";
import { useParams } from "react-router-dom";
import { getFirestore } from "firebase/firestore";
import { deleteObject, getStorage, ref, getMetadata } from "firebase/storage";
import { api } from "../utils/axios-instance";

// images from public folder
const Danger = `${process.env.PUBLIC_URL}/assets/danger.webp`;

const DeleteFileConfirmation = ({
  fileName,
  owner,
  id,
  remove,
  currVersion,
  versions,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState("success"); // "success" or "error"
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const { deptName } = useParams();
  const context = useContext(fileContext);
  const {
    removeFile,
    setAnchorEl,
    updateDepartmentFiles,
    setRefreshRecycleBin,
  } = context;

  console.log(fileName, owner, id);

  const openDialog = () => {
    setIsOpen(true);
  };

  const closeDialog = () => {
    setIsOpen(false);
    setAnchorEl(null);
  };

  const restoreFile = async () => {
    try {
      const response = await api.delete(`/file/restore-file/${id}/`);
      // removeFile(id);
      if (response.status === 200) {
        removeFile(id);
        console.log("Remove File: ", removeFile);
        setSnackbarSeverity("success");
        setSnackbarMessage("File restored successfully.");
        setSnackbarOpen(true);
        removeFile(id);
        if (remove) {
          console.log("YES");
          remove(id);
        }

        setTimeout(() => {
          // closeDialog();
          // removeFile(id);

          // refresh recycle bin
          setRefreshRecycleBin(true);
        }, 0);
      } else {
        throw new Error(response.data.error || "Unknown error occurred");
      }
    } catch (error) {
      setSnackbarSeverity("error");
      setSnackbarMessage(error.message);
      setSnackbarOpen(true);
    }
  };

  const handleDelete = async () => {
    setLoading(true);
    let profileData = JSON.parse(secureLocalStorage.getItem("profileData"));
    let token = secureLocalStorage.getItem("token");

    // Check if the user is the owner of the file
    // console.log(fileName);

    console.log("profileData", profileData);
    if (profileData.id === owner || profileData.role_priv === "org_admin") {
      try {
        const storage = getStorage();
        const fileRef = ref(storage, `files/${profileData.org}/${id}`);

        console.log("Delete success", id);

        // Delete file from the bucket files collection and restore collection... rejects if any of the promises fails
        await Promise.all([
          api.delete(`/file/delete-file/${id}/`),
          api.delete(`/file/restore-file/${id}/`),
        ]);

        // mod: delete file from bucket at the end
        await deleteObject(fileRef);

        // If the file is not saved as a version, delete this copy from storage
        if (!versions.some((obj) => obj.id === currVersion)) {
          const fileRef = ref(
            storage,
            `files/${profileData.org}/${currVersion}`
          );

          // Delete the file
          deleteObject(fileRef)
            .then(() => {
              // File deleted successfully
              console.log("Deleted File Copy");
            })
            .catch((error) => {
              // Uh-oh, an error occurred!
              console.log(error);
            });
        }

        //Delete all version blobs from storage as well
        if (versions) {
          for (let version of versions) {
            const fileRef = ref(
              storage,
              `files/${profileData.org}/${version.id}`
            );
            await deleteObject(fileRef);
          }
        }

        // if (response01.status === 200) {
        //   setSnackbarSeverity("success");
        //   setSnackbarMessage("File restored successfully.");
        //   setSnackbarOpen(true);
        // }

        removeFile(id);
        if (remove) {
          console.log("YES");
          remove(id);
        }

        if (deptName) updateDepartmentFiles(deptName);
        // refresh recycle bin
        setRefreshRecycleBin(true);

        setTimeout(() => {
          closeDialog();
          removeFile(id);
        }, 3000);
      } catch (error) {
        console.error("Error occurred while deleting the file:", error.message);
        setSnackbarSeverity("error");
        setSnackbarMessage("Error deleting the file.");
        setSnackbarOpen(true);
      }
    } else {
      // Display Snackbar message if the user is not the owner of the file
      setSnackbarSeverity("error");
      setSnackbarMessage("You are not the owner of the file.");
      setSnackbarOpen(true);
      // refresh recycle bin
      setRefreshRecycleBin(true);

      // wait before closing the dialog
      // because the snackbar needs time to appear
      setTimeout(() => {
        closeDialog();
      }, 3000);
    }
    setLoading(false);
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);

    // Close the dialog after 3 seconds
  };

  return (
    <>
      <div className="flex flex-col space-y-1 text-left">
        <button
          onClick={openDialog}
          className="text-[#D1293D] hover:bg-red-200 hover:text-red-600 duration-100   px-2 py-1 border rounded-md"
        >
          Delete File
        </button>
        <button
          onClick={restoreFile}
          className="text-[#167e27] hover:bg-green-200 hover:text-green-600 duration-100  px-2 py-1 border rounded-md"
        >
          Restore File
        </button>
      </div>

      <Dialog
        open={isOpen}
        onClose={closeDialog}
        PaperProps={{
          style: {
            borderRadius: "5px",
          },
        }}
      >
        {/* <DialogTitle>Confirm Delete</DialogTitle> */}
        <DialogContent
          style={{
            backgroundColor: "#FEF2F2",
          }}
        >
          <div className="my-2 p-3 flex flex-col justify-center items-center gap-6">
            <img src={Danger} alt="." />
            <h2 className="text-2xl font-semibold text-gray-700">
              Are you sure?
            </h2>
            <div className="text-center">
              <p>
                This action will delete the{" "}
                <strong className="hover:underline">
                  {fileName.split("_TS=")[0]}
                </strong>{" "}
                file.
              </p>
              <p>You won't be able to revert this action!</p>
            </div>

            <div className="flex flex-row justify-center items-center gap-2">
              <button
                className="px-4 py-1 rounded-lg shadow-sm bg-red-500 text-white"
                onClick={handleDelete}
              >
                {loading ? "Loading..." : "Confirm"}
              </button>
              <button
                className="px-4 py-1 mx-2 rounded-lg shadow-sm border border-gray-300"
                onClick={closeDialog}
                color="primary"
              >
                Cancel
              </button>
            </div>

            <Snackbar
              open={snackbarOpen}
              autoHideDuration={6000}
              onClose={handleSnackbarClose}
            >
              <MuiAlert
                elevation={6}
                variant="filled"
                onClose={handleSnackbarClose}
                severity={snackbarSeverity}
              >
                {snackbarMessage}
              </MuiAlert>
            </Snackbar>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default DeleteFileConfirmation;
